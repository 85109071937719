import Vue from "vue"
import moment from "moment"

import ApiService from "../ApiService"

class ApigDashboardService extends ApiService {
  /**
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super(`${process.env.VUE_APP_APIG_API}lod-dashboard`, parameters)
  }

  /**
   * @param {string} id
   * @return {Promise<any | never>}
   */
  getUserDataByOrderId(id) {
    return this.submit("get", `${this.endpoint}/orders/${id}`).then(res => {
      return res.data
    })
  }

  /**
   * @param {string} email
   * @return {Promise<any | never>}
   */
  getUserDataByEmail(email) {
    return this.submit("get", `${this.endpoint}/orders/email/${email}`).then(
      res => {
        return res.data
      }
    )
  }

  /**
   * @param {string} url
   * @param {string} name Name of the downloaded file
   * @param {boolean} [useOriginalFilename=false] Prefer original filename if available
   * @return {Promise<void>}
   */
  downloadPDF(url, name, useOriginalFilename = false) {
    return Vue.$http
      .request({
        method: "get",
        url,
        headers: {
          Accept: "application/pdf"
        },
        responseType: "blob"
      })
      .then(response => {
        const url = window.URL.createObjectURL(response.data)
        const link = document.createElement("a")
        link.href = url
        link.target = "_blank"
        const contentDisposition = response.headers["content-disposition"]
        let filename = name
        if (
          useOriginalFilename &&
          contentDisposition &&
          contentDisposition.includes("filename=")
        ) {
          filename = contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .replace('"', "")
        }
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
  }

  /**
   * @param {string} orderId
   * @param {string[]} vouchers
   * @param {string} email
   * @param {boolean} isGift
   * @return {Promise<any | never>}
   */
  resendVouchers(orderId, vouchers, email, isGift = false) {
    const encodedEmail = encodeURIComponent(email)
    const encodedVouchers = encodeURIComponent(vouchers.join(","))
    const isGiftNum = isGift ? 1 : 0
    const query = `order_id=${orderId}&vouchers=${encodedVouchers}&email=${encodedEmail}&is_gift=${isGiftNum}`

    return this.submit("get", `${this.endpoint}/resend-vouchers?${query}`).then(
      res => {
        return res.data
      }
    )
  }

  /**
   * @param {string} voucherId
   * @param {string} status
   */
  updateVoucherStatus(voucherId, status) {
    return this.submit("patch", `${this.endpoint}/vouchers/${voucherId}`, {
      VOUCHER_STATUS: status
    }).then(res => {
      return res.data
    })
  }

  /**
   * @param {string} sku
   * @param {Date} date
   */
  updateVoucherRedemptionDate(sku, date) {
    const dt = encodeURIComponent(moment(date).format("YYYY-MM-DD HH:mm:ss"))
    return this.submit(
      "post",
      `${this.endpoint}/vouchers/redemption-date/SKU-${sku}/${dt}`,
      {
        VOUCHER_STATUS: status
      }
    ).then(res => {
      return res.data
    })
  }

  /**
   * @param {string} sku
   * @return {Promise<any | never>}
   */
  getSku(sku) {
    return this.submit("get", `${this.endpoint}/skus/${sku}`).then(res => {
      return res.data
    })
  }

  /**
   * @param {string} bookingId
   * @return {Promise<Object>}
   */
  getBooking(bookingId) {
    return this.submit(
      "get",
      `${this.endpoint}/hotel-bookings/${bookingId}`
    ).then(res => {
      return res.data
    })
  }

  getUploadCustomVoucherCodesEndpoint() {
    return `${this.endpoint}/vouchers/uploadCustomVoucherCodes`
  }

  getUniversalImportEndpoint() {
    return `${this.endpoint}/subscriber/universal`
  }

  getBatchUpdateVoucherStatusEndpoint() {
    return `${this.endpoint}/batch/voucherStatusChange`
  }

  getBatchRefundEndpoint() {
    return `${this.endpoint}/batch/refund`
  }

  getIcons() {
    return this.submit("get", `${this.endpoint}/icons`).then(res => {
      return res.data
    })
  }
}

export default ApigDashboardService
