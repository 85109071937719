import Vue from "vue"
import Router from "vue-router"
import Login from "./views/Login"
import Page404 from "./views/Page404"
import Home from "./views/Home"

import { roles } from "./store/account/acl"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "home" */ "./views/Dashboard.vue"),
          meta: {
            permission: `${roles.ROLE_DASHBOARD_USER}`,
            fail: "/login",
            group: "home"
          }
        },
        {
          path: "validate-offer",
          name: "monitoring.offer-validator",
          component: () =>
            import(
              /* webpackChunkName: "offer-validator" */ "./views/monitoring/OfferValidator.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "monitoring"
          }
        },
        {
          path: "validator-log",
          name: "monitoring.validator-log",
          component: () =>
            import(
              /* webpackChunkName: "validator-log" */ "./views/monitoring/OfferValidatorLog.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "monitoring"
          }
        },
        {
          path: "moneris-check",
          name: "monitoring.moneris",
          component: () =>
            import(
              /* webpackChunkName: "validator-log" */ "./views/monitoring/MonerisTransactions.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "monitoring"
          }
        },
        {
          path: "/vouchers/search/:orderId?",
          name: "voucher.list",
          component: () =>
            import(
              /* webpackChunkName: "voucher" */ "./views/voucher/List.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "voucher"
          }
        },
        {
          path: "/vouchers/redemption",
          name: "voucher.redemption",
          component: () =>
            import(
              /* webpackChunkName: "voucher-redemption" */ "./views/voucher/BatchUpdateRedemptionDate"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "voucher"
          }
        },
        {
          path: "/vouchers/custom-codes/create",
          name: "custom_code.create",
          component: () =>
            import(
              /* webpackChunkName: "custom-code-create" */ "./views/customCode/Create.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "voucher"
          }
        },
        {
          path: "/vouchers/custom-codes",
          name: "custom_code.list",
          component: () =>
            import(
              /* webpackChunkName: "custom-code-list" */ "./views/customCode/List.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "voucher"
          }
        },
        {
          path: "/vouchers/batch-update",
          name: "batch_update_voucher_status",
          component: () =>
            import(
              /* webpackChunkName: "batch_update_voucher_status" */ "./views/voucher/BatchUpdateVoucherStatus"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "voucher"
          }
        },
        {
          path: "/orders",
          name: "order.list",
          component: () =>
            import(
              /* webpackChunkName: "orders-list" */ "./views/order/List.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          }
        },
        {
          path: "/orders/monitoring",
          name: "order.monitoring",
          component: () =>
            import(
              /* webpackChunkName: "orders-monitoring" */ "./views/order/Monitoring.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          }
        },
        {
          path: "/order/:orderId",
          name: "order.single",
          component: () =>
            import(
              /* webpackChunkName: "orders-single" */ "./views/order/Single.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          },
          children: [
            {
              path: "refunds",
              name: "refund.index",
              component: () =>
                import(
                  /* webpackChunkName: "refunds" */ "./views/refund/Index.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "order"
              }
            },
            {
              path: "self-refund/:orderItemId",
              name: "selfRefund",
              component: () =>
                import(
                  /* webpackChunkName: "self-refund" */ "./views/refund/SelfRefund.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "order"
              }
            },
            {
              path: "resend",
              name: "order.resend",
              component: () =>
                import(
                  /* webpackChunkName: "orders-list" */ "./views/order/ResendVouchersTickets.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "order"
              }
            }
          ]
        },
        {
          path: "/refunds/batch-refund",
          name: "refund.batch_refund",
          component: () =>
            import(
              /* webpackChunkName: "batch_refund" */ "./views/refund/BatchRefund"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          }
        },
        {
          path: "/refund-history",
          name: "refund.history",
          component: () =>
            import(
              /* webpackChunkName: "refund-history" */ "./views/refund/History.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          }
        },
        {
          path: "/refunds/refund-requests",
          name: "refund.refund-requests",
          component: () =>
            import(
              /* webpackChunkName: "refund_requests" */ "./views/refund/RefundRequests"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "order"
          }
        },
        {
          path: "/promo-code-generator",
          name: "promo.generator",
          component: () =>
            import(
              /* webpackChunkName: "promocodegenerator" */ "./views/promo/PromoCodeGenerator.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "promo"
          }
        },
        {
          path: "/cancel-promo-code",
          name: "promo.cancel",
          component: () =>
            import(
              /* webpackChunkName: "cancelpromocode" */ "./views/promo/PromoCodeInvalidation.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "promo"
          }
        },
        {
          path: "/search-promo-code",
          name: "promo.search",
          component: () =>
            import(
              /* webpackChunkName: "cancelpromocode" */ "./views/promo/PromoCodeSearch.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "promo"
          }
        },
        {
          path: "last-chance",
          name: "lastchance.index",
          component: () =>
            import(
              /* webpackChunkName: "lastchanceemail" */ "./views/lastChance/Index.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_MARKETING}`,
            fail: "/login",
            group: "lastchance"
          }
        },
        {
          path: "/subscribers/universal-import",
          name: "subscriber.import",
          component: () =>
            import(
              /* webpackChunkName: "universal-import" */ "./views/subscriber/UniversalImport.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_MARKETING}`,
            fail: "/login",
            group: "subscriber"
          }
        },
        {
          path: "/promoters",
          name: "tuango.promoters",
          component: () =>
            import(
              /* webpackChunkName: "promoters" */ "./views/tuango/PromoterManager"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "tuango"
          }
        },
        {
          path: "/booking/configurator",
          name: "booking.configurator",
          component: () =>
            import(
              /* webpackChunkName: "booking-config" */ "./views/booking/Configurator"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "booking"
          }
        },
        {
          path: "/booking/list",
          name: "booking.list",
          component: () =>
            import(
              /* webpackChunkName: "booking-list" */ "./views/booking/List"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "booking"
          }
        },
        {
          path: "/icons",
          name: "tuango.icons",
          component: () =>
            import(/* webpackChunkName: "icon-finder" */ "./views/IconFinder"),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "tuango"
          }
        },
        {
          path: "/users/info",
          name: "user.info",
          component: () =>
            import(
              /* webpackChunkName: "universal-import" */ "./views/user/UserInfo"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "user"
          }
        },
        {
          path: "/user-reviews",
          name: "user.reviews",
          component: () =>
            import(
              /* webpackChunkName: "user.reviews" */ "./views/userReviews/ManageReviews"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "user-reviews"
          }
        },
        {
          path: "/user-search-reviews",
          name: "user.search-reviews",
          component: () =>
            import(
              /* webpackChunkName: "user.reviews" */ "./views/userReviews/SearchReviews"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "user-reviews"
          }
        },
        {
          path: "/users/clean-up",
          name: "user.cleanUp",
          component: () =>
            import(
              /* webpackChunkName: "user-cleanup" */ "./views/user/CleanUp"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "user"
          }
        },
        {
          path: "/merchants",
          name: "merchantUsers",
          component: () =>
            import(
              /* webpackChunkName: "merchants" */ "./views/user/Merchants"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "user"
          }
        },
        {
          path: "/rewards/history",
          name: "rewards.history",
          component: () =>
            import(
              /* webpackChunkName: "rewards-history" */ "./views/tuangoRewards/RewardsHistory"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "rewards"
          }
        },
        {
          path: "/rewards/promotions",
          name: "rewards.promotions",
          component: () =>
            import(
              /* webpackChunkName: "rewards-promotions" */ "./views/tuangoRewards/Promotions"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "rewards"
          }
        },
        {
          path: "fraud",
          name: "fraud.index",
          component: () =>
            import(/* webpackChunkName: "fraud" */ "./views/fraud/Index.vue"),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "fraud"
          },
          children: [
            {
              path: "",
              name: "fraud.transactions",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-transactions" */ "./views/fraud/Transactions.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "history",
              name: "fraud.history",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-history" */ "./views/fraud/History.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "address",
              name: "fraud.address",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-address" */ "./views/fraud/Address.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "domain-blacklist",
              name: "fraud.domain-blacklist",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-domain-blacklist" */ "./views/fraud/DomainBlacklist.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "domain-whitelist",
              name: "fraud.domain-whitelist",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-domain-whitelist" */ "./views/fraud/DomainWhitelist.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "fraudster",
              name: "fraud.fraudster",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-fraudster" */ "./views/fraud/Fraudster.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "keywords",
              name: "fraud.keywords",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-keywords" */ "./views/fraud/Keywords.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "monitoring",
              name: "fraud.monitoring",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-monitoring" */ "./views/fraud/Monitoring.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "waitlist",
              name: "fraud.waitlist",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-waitlist" */ "./views/fraud/Waitlist.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            },
            {
              path: "whitelist",
              name: "fraud.whitelist",
              component: () =>
                import(
                  /* webpackChunkName: "fraud-whitelist" */ "./views/fraud/Whitelist.vue"
                ),
              meta: {
                permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
                fail: "/login",
                group: "fraud"
              }
            }
          ]
        },
        {
          path: "/data-warehouse/affiliate-sales",
          name: "dw.affiliate-sales",
          component: () =>
            import(
              /* webpackChunkName: "affiliate-sales" */ "./views/dataWarehouse/AffiliateSales.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "datawarehouse"
          }
        },
        {
          path: "/data-warehouse/affiliate-sales-list/:offerIdOrSku?",
          name: "dw.affiliate-sales-list",
          component: () =>
            import(
              /* webpackChunkName: "affiliate-sales-list" */ "./views/dataWarehouse/AffiliateSalesList.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "datawarehouse"
          }
        },
        {
          path: "/data-warehouse/resync-et-data",
          name: "dw.resync-et-data",
          component: () =>
            import(
              /* webpackChunkName: "affiliate-sales" */ "./views/dataWarehouse/ResyncETData.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}`,
            fail: "/login",
            group: "datawarehouse"
          }
        },

        {
          path: "/hotel-avalability",
          name: "hotel.availability",
          component: () =>
            import(
              /* webpackChunkName: "hotel-availability" */ "./views/hotel/AvailabilityChecker.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}|${roles.ROLE_EDITOR}`,
            fail: "/login",
            group: "hotels"
          }
        },
        {
          path: "/reservation/:bookingId?",
          name: "hotel.reservation-details",
          component: () =>
            import(
              /* webpackChunkName: "hotel-reservation" */ "./views/hotel/ReservationManager.vue"
            ),
          meta: {
            permission: `${roles.ROLE_IT}|${roles.ROLE_CUSTOMER_SERVICE}`,
            fail: "/login",
            group: "hotels"
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        permission: "any"
      }
    },
    {
      // not found handler
      path: "*",
      name: "404",
      component: Page404,
      meta: {
        auth: false,
        permission: "any"
      }
    }
  ]
})

// in store import Vue and use the router as Vue.router
Vue.router = router

export default router
