<template>
  <!-- BEGIN SIDEBAR -->
  <div class="page-sidebar-wrapper">
    <!-- BEGIN SIDEBAR -->

    <div class="page-sidebar navbar-collapse" :class="{ collapse: !isOpen }">
      <!-- BEGIN SIDEBAR MENU -->
      <ul
        class="page-sidebar-menu page-header-fixed "
        :class="{ 'page-sidebar-menu-closed': !isOpen }"
        style="padding-top: 20px"
      >
        <v-sidebar-item-group
          v-if="$can('IT|CUSTOMER_SERVICE')"
          routeGroup="order"
          title="Orders"
          icon="fas fa-shopping-cart"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="order.list"
                  title="Search orders"
                  icon="fas fa-search"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="refund.history"
                  title="Refund history"
                  icon="fas fa-donate"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="refund.batch_refund"
                  title="Batch refund"
                  icon="fas fa-file-invoice-dollar"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="refund.refund-requests"
                  title="Refund requests"
                  icon="far fa-money-bill-alt"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="order.monitoring"
                  title="Order monitoring"
                  icon="fas fa-exclamation-triangle"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
          routeGroup="promo"
          title="Promo codes"
          icon="fas fa-percent"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="promo.generator"
                  title="Create promo codes"
                  icon="fas fa-boxes"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="promo.cancel"
                  title="Cancel promo code"
                  icon="fas fa-ban"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="promo.search"
                  title="Search promo code"
                  icon="fas fa-search"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|CUSTOMER_SERVICE|EDITOR')"
          routeGroup="voucher"
          title="Vouchers"
          icon="fas fa-barcode"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="voucher.list"
                  title="Search vouchers"
                  icon="fas fa-search"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|EDITOR')"
                  routeName="custom_code.create"
                  title="Create custom codes"
                  icon="fas fa-folder-plus"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|EDITOR')"
                  routeName="custom_code.list"
                  title="Custom codes usage"
                  icon="far fa-file-alt"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="batch_update_voucher_status"
                  title="Batch update"
                  icon="fas fa-wrench"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE|EDITOR')"
                  routeName="voucher.redemption"
                  title="Update redemption date"
                  icon="far fa-calendar-alt"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|MARKETING')"
          routeGroup="subscriber"
          title="Subscribers"
          icon="fas fa-user-friends"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="subscriber.import"
                  title="Universal import tool"
                  icon="fas fa-cloud-upload-alt"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
          routeGroup="monitoring"
          title="Monitoring"
          icon="fas fa-heartbeat"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="monitoring.offer-validator"
                  title="Validate offer"
                  icon="fas fa-stethoscope"
                ></v-sidebar-link>

                <v-sidebar-link
                  routeName="monitoring.validator-log"
                  title="Validator log"
                  icon="fas fa-exclamation-triangle"
                ></v-sidebar-link>

                <v-sidebar-link
                  routeName="monitoring.moneris"
                  title="Moneris Transactions"
                  icon="fas fa-binoculars"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|MARKETING')"
          routeGroup="lastchance"
          title="Last Chance Email"
          icon="fas fa-envelope-square"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="lastchance.index"
                  title="Last Chance Email Tool"
                  icon="fas fa-envelope-open-text"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
          routeGroup="booking"
          title="Booking"
          icon="far fa-clock"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  v-if="$can('IT|EDITOR')"
                  routeName="booking.configurator"
                  title="Booking Configurator"
                  icon="fas fa-tools"
                ></v-sidebar-link>

                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="booking.list"
                  title="Search bookings"
                  icon="far fa-calendar-alt"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|CUSTOMER_SERVICE')"
          routeGroup="fraud"
          title="Fraud tool"
          icon="fas fa-user-secret"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="fraud.transactions"
                  title="Transactions"
                  icon="fas fa-binoculars"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.history"
                  title="History"
                  icon="fas fa-history"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.waitlist"
                  title="Waitlist"
                  icon="far fa-clock"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.fraudster"
                  title="Fraudster"
                  icon="fas fa-user-slash"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.monitoring"
                  title="Monitoring"
                  icon="fas fa-search-dollar"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.address"
                  title="Fraud address"
                  icon="fas fa-map-marked-alt"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.domain-blacklist"
                  title="Domains blacklist"
                  icon="far fa-address-book"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.domain-whitelist"
                  title="Domains whitelist"
                  icon="fas fa-address-book"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.keywords"
                  title="Keywords"
                  icon="fas fa-tags"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="fraud.whitelist"
                  title="Whitelist"
                  icon="fas fa-user-check"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|CUSTOMER_SERVICE|EDITOR')"
          routeGroup="user"
          title="Users"
          icon="fas fa-users"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="user.info"
                  title="User Info"
                  icon="fas fa-user"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="user.cleanUp"
                  title="User Delete/Unsub"
                  icon="fas fa-broom"
                ></v-sidebar-link>
                <v-sidebar-link
                  v-if="$can('IT|EDITOR')"
                  routeName="merchantUsers"
                  title="Merchant Users"
                  icon="fas fa-user-friends"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
          routeGroup="rewards"
          title="Tuango rewards"
          icon="fas fa-coins"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="rewards.history"
                  title="Rewards history"
                  icon="far fa-clock"
                ></v-sidebar-link>

                <v-sidebar-link
                  v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
                  routeName="rewards.promotions"
                  title="Promotion campaigns"
                  icon="fas fa-boxes"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|CUSTOMER_SERVICE')"
          routeGroup="user-reviews"
          title="Customer reviews"
          icon="fas fa-solid fa-comment"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="user.reviews"
                  title="Manage reviews"
                  icon="fas fa-solid fa-pen"
                ></v-sidebar-link>
                <v-sidebar-link
                  routeName="user.search-reviews"
                  title="Search reviews"
                  icon="fas fa-search"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR')"
          routeGroup="tuango"
          title="Tuango Admin"
          icon="fas fa-cogs"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="tuango.promoters"
                  title="Promoter manager"
                  icon="fas fa-sort-amount-up-alt"
                ></v-sidebar-link>

                <v-sidebar-link
                  routeName="tuango.icons"
                  title="Icon Finder"
                  icon="fas fa-icons"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR')"
          routeGroup="datawarehouse"
          title="Data Warehouse"
          icon="fas fa-database"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="dw.affiliate-sales"
                  title="Affiliate Sales"
                  icon="fas fa-money-bill-wave"
                ></v-sidebar-link>

                <v-sidebar-link
                  routeName="dw.affiliate-sales-list"
                  title="Affiliate Sales Reports"
                  icon="fas fa-table"
                ></v-sidebar-link>

                <v-sidebar-link
                  v-if="$can('IT')"
                  routeName="dw.resync-et-data"
                  title="Resync ET Data"
                  icon="fas fa-border-all"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <v-sidebar-item-group
          v-if="$can('IT|EDITOR|CUSTOMER_SERVICE')"
          routeGroup="hotels"
          title="Hotel reservations"
          icon="fas fa-solid fa-hotel"
        >
          <template slot-scope="props">
            <el-collapse-transition>
              <ul class="sub-menu" v-show="props.expanded && isOpen">
                <v-sidebar-link
                  routeName="hotel.availability"
                  title="Hotel availability"
                  icon="fas fa-solid fa-calendar-alt"
                ></v-sidebar-link>

                <v-sidebar-link
                  v-if="$can('IT|CUSTOMER_SERVICE')"
                  routeName="hotel.reservation-details"
                  title="Cancel reservation"
                  icon="far fa-calendar-times"
                ></v-sidebar-link>
              </ul>
            </el-collapse-transition>
          </template>
        </v-sidebar-item-group>

        <li v-if="$can('IT')" class="nav-item">
          <a :href="tabDashboardUrl" class="nav-link">
            <i class="fas fa-sitemap"></i>
            <span class="title">Dashboard Tab</span>
          </a>
        </li>
      </ul>
      <!-- END SIDEBAR MENU -->
    </div>
    <!-- END SIDEBAR -->
  </div>
  <!-- END SIDEBAR -->
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import CollapseTransition from "element-ui/lib/transitions/collapse-transition"

import VSidebarItemGroup from "./SidebarItemGroup"
import VSidebarLink from "./SidebarLink"

const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "sidebar"
)

export default {
  components: {
    VSidebarItemGroup,
    VSidebarLink,
    "el-collapse-transition": CollapseTransition
  },
  data() {
    return {
      desktopMq: 992,
      collapsed: true
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.isOpen
    }),
    tabDashboardUrl() {
      if (window.location.href.includes("localhost")) {
        return "http://localhost:8083"
      } else if (window.location.href.includes("dashboard-dev")) {
        return "https://dashboard-dev.tuango.ca"
      } else {
        return "https://dashboard.tuango.ca"
      }
    }
  },
  methods: {
    ...mapMutations(["expandCollapse"]),
    ...mapActions(["toggleSidebar"]),
    windowResize() {
      if (this.$mq.below(this.desktopMq)) {
        this.expandCollapse(false)
      } else {
        this.expandCollapse(true)
      }
    },
    changeBodyClass(isOpen) {
      if (isOpen) {
        document.body.classList.remove("page-sidebar-closed")
      } else {
        document.body.classList.add("page-sidebar-closed")
      }
    }
  },
  watch: {
    "$mq.resize": "windowResize"
  },
  created() {
    const vm = this
    this.$store.watch(
      state => {
        return state.sidebar.isOpen
      },
      val => {
        vm.changeBodyClass(val)
      }
    )
  },
  mounted() {
    this.windowResize()
  }
}
</script>
